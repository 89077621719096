
const voteResultStatusStyling = (overallResult) => {
  // NOTE: This function is a direct copy of the handlebars helper 'voteResultStatusStyling' from email-template.service.js
  // When making changes to this function, make sure to also update the handlebars helper in email-template.service.js

  // Darken the base colors a bit with 'filter' for better readability:
  const lowercaseResult = overallResult.toLowerCase();
  if (
    lowercaseResult.includes('aangenomen')
    || lowercaseResult.includes('oannommen') // Frisian for dlrc
  ) return 'font-weight: bold; color: #37ada2; filter: brightness(80%);';
  if (
    lowercaseResult.includes('verworpen')
    || lowercaseResult.includes('fersmiten') // Frisian for dlrc
  ) return 'font-weight: bold; color: #e64415; filter: brightness(80%);';
  if (
    lowercaseResult.includes('ingetrokken')
  ) return 'font-weight: bold; color: #bbb; filter: brightness(80%);';
  return '';
};

const sortOrder = ['Voor', 'Niet deelgenomen', 'Tegen'];
const sortVoteResults = (voteResults, reversedNegative = false) => voteResults.sort((a, b) => {
  // First sort on voteType
  const aIndex = sortOrder.indexOf(a.voteKind);
  const bIndex = sortOrder.indexOf(b.voteKind);
  if (aIndex !== bIndex) return aIndex - bIndex;

  // Then on fractionSize
  const sizeA = Number(a.fractionSize);
  const sizeB = Number(b.fractionSize);
  if (sizeB !== sizeA) {
    // Sort 'Tegen' and 'Niet deelgenomen' in opposite direction voor better visual:
    if (reversedNegative && a.voteKind !== 'Voor') return sizeA - sizeB;
    return sizeB - sizeA;
  }

  // Sort alphabetically on 'actorFraction'
  // Usefull for 'hoofdelijke stemming'
  if (a.actorFraction !== b.actorFraction) return a.actorFraction > b.actorFraction ? 1 : -1;

  // Then alphabetically
  // Sort 'Tegen' and 'Niet deelgenomen' in opposite direction voor better visual:
  if (reversedNegative && a.voteKind !== 'Voor') return b.actorName > a.actorName ? 1 : -1;
  return a.actorName > b.actorName ? 1 : -1;
});

module.exports = {
  voteResultStatusStyling,
  sortVoteResults,
};
