const userLocale = JSON.parse(localStorage.getItem('locale')) || 'nl';

// Convert the grouping structure to one the treeview component understands
const convertGroupToFilterStructure = (groupPath, group) => {
  const defaultLocale = 'nl';

  const locale = group.localization[userLocale] ? userLocale : defaultLocale;

  const localization = typeof group.localization[locale] === 'string'
    ? group.localization[locale] : group.localization[locale].plural;

  return {
    groupPath,
    localization,
    children: group.subGroups
      ? Object.keys(group.subGroups).map((groupCode) => convertGroupToFilterStructure(
        `${groupPath}/${groupCode}`,
        group.subGroups[groupCode],
      ))
      : undefined,
  };
};

// TODO get this from the actual grouping
const euInstitutionCodeToName = (code) => {
  if (code === 'EUROCOM') return 'Europese Commissie';
  if (code === 'EUROPARL') return 'Europees Parlement';
  if (code === 'EUROCOU') return 'Consilium';
  if (code === 'EP-COM-OVERIG') return 'EP overig';
  return '???';
};

const formatLocalization = (localization, groupPath) => {
  if (groupPath === 'EU-SH' || groupPath === 'EU-MD') return `${localization}*`;
  return localization;
};

module.exports = {
  convertGroupToFilterStructure,
  euInstitutionCodeToName,
  formatLocalization,
};
